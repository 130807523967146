.defaultButton {
    @include _all_transiition;
    color: $color-k-button;
    background-color: $color-k-black;
    cursor: pointer;
    border: none;
    box-shadow: 0 0 0 0.5px $color-k-button;
    min-width: 120px;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 5px;
    padding: 0 1rem;

    .loader {
        color: $color-k-button;
    }

    &:not(.disabled):not(.inverted):focus {
        box-shadow: 0 0 0 2px $color-k-button;
        font-weight: 700;
    }

    &:not(.disabled):hover {
        background-color: $color-k-button;
        color: $color-k-black;
        font-weight: 600;

        .loader {
            color: black;
        }
    }

    &.disabled {
        opacity: 0.4;
    }

    &.inverted {
        background-color: $color-k-button;
        box-shadow: none;

        .loader {
            color: black;
        }
    }

    &.s, &.m {
        box-shadow: 0 0 0 1px $color-k-yellow;

        &:not(.disabled):not(.inverted):focus {
            box-shadow: 0 0 0 3px $color-k-button;
        }
    }

    &.red {
        background-color: $color-k-grey-text;
        color: $color-k-red;
        box-shadow: 0 0 0 2px $color-k-red;

        &:not(.disabled):not(.inverted):focus {
            background-color: $color-k-grey;
            box-shadow: 0 0 0 3px $color-k-red;
        }

        &:not(.disabled):not(.inverted):hover {
            color: $color-k-grey;
            background-color: $color-k-red;
        }
    }

    &.grey {
        color: $color-k-grey-text;
        box-shadow: 0 0 0 2px $color-k-grey-text;

        &:not(.disabled):not(.inverted):focus {
            box-shadow: 0 0 0 3px $color-k-grey-text;
        }

        &:not(.disabled):not(.inverted):hover {
            color: $color-k-black;
            background-color: $color-k-grey;
            box-shadow: 0 0 0 3px $color-k-grey;
        }
    }

    &.s {
        height: 35px;
        font-size: 14px;
    }

    &.m {
        height: 40px;
    }
    
    &.l {
        height: 50px;
        font-size: 20px;
    }

    &.inverted {
        color: $color-k-black;
    }

    &.rejected {
        background-color: $color-k-grey;
        border: 1px solid $color-k-red;
        color: $color-k-red;

        &:not(.disabled):hover {
            background-color: $color-white;
        }
    }
}

.hLoginButton {
    min-width: 150px;

    @include _950 {
        min-width: 80px;
    }
}

.userMenuWrapper {
    position: relative;

    .userMenu {
        position: absolute;
        left: 0;
        z-index: 99;
        overflow: hidden;
        border-radius: 5px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
        background-color: $color-k-dark-grey;
        width: 100%;
        max-height: 0;
        opacity: 0;
        transition: max-height 0.5s ease-in-out, opacity 0.2s ease-in-out;

        * {
            padding: 1rem !important;
            font-weight: 600;
            cursor: pointer;
            transition: color 0.3s ease-in-out;

            &:hover {
                color: $color-k-button;
            }
        }

        &.show {
            max-height: 300px;
            opacity: 1;
        }
    }
}

.userMenuButton {
    position: relative;
    font-size: 15px;
    font-weight: 700;

    &.unread::after {
        top: 7px;
        right: 7px;
    }

    &::before {
        position: absolute;
        bottom: 7px;
        right: 7px;
        content: "";
        background: url($arrow-menu-icon) top left / cover no-repeat;
        width: 11px;
        height: 6px;
        transition: rotate 0.2s ease-in-out;
    }

    &.show::before {
        rotate: 180deg;
    }

    &:hover {
        font-weight: 700 !important;
    }
}

.searchButton {
    position: relative;
    width: 40px;
    height: 40px;
    background-color: $color-trans-safe;
    border: none;
    z-index: 1;

    &::before {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 8px;
        left: 8px;
        content: url($search-icon)
    }
}

.backButton {
    cursor: pointer;
    position: relative;
    width: 30px;
    height: 40px;
    background-color: $color-trans-safe;
    border: none;
    z-index: 1;

    &.contain {
        line-height: 1.11;
        height: unset;
        width: unset;
        color: $color-k-yellow;
        font-weight: 500;
        font-size: 30px;
        padding-left: 40px;
    }

    @include icon_mask("../../icons/arrow.svg", 18px, 30px, $color-k-yellow);
    
    &::before{
        transform: rotate(180deg);
    }
    
}

.crossButton {
    cursor: pointer;
    position: relative;
    width: 1.8rem;
    height: 1.8rem;
    background-color: $color-trans-safe;
    border: none;
    z-index: 1;

    @include icon_mask("../../icons/cross.svg", 1.8rem, 1.8rem, $color-k-yellow);

    &.grey {
        &::before {
            background-color: $color-k-grey;
        }  
    }
}

.heartButton {
    @include _all_transiition;
    @include icon_mask("../../icons/heartOutlined.svg", 1.5rem, 1.5rem);

    &:hover {
        &::before {
            background-color: $color-k-yellow;
        }  
    }

    &.toggled {
        @include icon_mask("../../icons/heartFilled.svg", 1.5rem, 1.5rem);

        &::before {
            background-color: $color-k-yellow;
        }

        &:hover::before {
            background-color: $color-k-grey-text;
        }
    }
}

.eyeButton {
    @include icon_mask("../../icons/eye.svg", 1.5rem, 1.5rem);

    &.toggled {
        &::before {
            background-color: $color-k-yellow;
        }
    }
}

.closeButton {
    cursor: pointer;
    background-color: transparent;
    border: 0;
    outline: 0;
    padding: 0 6px 0 0;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: table-cell;
    vertical-align: middle;

    &::after {
        cursor: pointer;
        background-color: $color-k-button;
        color: #fff;
        border-radius: 50%;
        height: 16px;
        width: 16px;
        padding: 2px;
        font-size: 12px;
        line-height: 1;
        text-align: center;
        display: table-cell;
        vertical-align: middle;
        content: "\00d7";
    }
}

.ghostButton {
    background: $color-trans-safe;
    font-size: 20px;
    border: unset;
    color: $color-k-button;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    margin-top: 24px !important;

    &.inactive {
        color: $color-k-grey-text;
        cursor: default;
    }

    &:hover:not(.inactive) {
        text-decoration: underline;
    }
}