.videoCard {
    margin: 2px;
    width: 200px;
    min-height: 235px;
    @include flexbox(flex, column);
    justify-content: space-between;

    img, h3 {
        cursor: pointer;
        // line-break: anywhere;
        word-break: break-word;
    }

    .cardContent {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    img {
        border: 1px solid $color-k-grey-text;
        object-fit: cover;
        aspect-ratio: 16/9;
        @include _all_transiition;
    }

    &:hover {
        img {
            box-shadow: 0 0 0 2px $color-k-red;
        }
    }

    .authorInfo {
        font-size: 14px;
        margin-bottom: 0.4rem;

        .author {
            font-style: italic;
            font-weight: 600;
        }

        .team {
            margin-top: 0.2rem;
            font-weight: 500;
        }
    }

    .metaBar {
        @include flexbox(flex, row, null, null, nowrap, 0, 0, null, space-between, center);

        .ratingAndViews {
            @include flexbox(flex, row);
            gap: 0.6rem;
        }
    }
}