.ratingMeta, .viewsMeta, .dateMeta {
    position: relative;
    font-size: 12px;
    color: $color-k-grey-text;
    font-weight: 600;

    &.fullSize {
        font-size: 20px;
    }
}

.ratingMeta, .viewsMeta {
    padding-left: 20px;

    &::before {
        position: absolute;
        display: block;
        width: 20px;
        height: 20px;
        top: 0;
        left: 0;
    }

    &.fullSize {
        font-weight: 700;
        
        &::before {
            scale: 1.12;
        }
    }
}

.viewsMeta::before {
    content: url($eye-icon);
}

.ratingMeta::before {
    content: url($rating-icon);
}

.arrowElem {
    position: absolute;
    display: block;
    background-image: url($arrow-icon);
    width: 15px;
    height: 25px;
    opacity: 0;
}

.arrow-left {
    transform: rotate(180deg);
}

.arrow-top {
    transform: rotate(270deg);
}

.arrow-bot {
    transform: rotate(90deg);
}

.loaderWrapper {
    width: 100%;
}

.loaderFullScreen {
    @include flexbox(flex, column, center);
    flex-grow: 1;
}

.loaderSplash {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    @include flexbox(flex, column, center);
    @include _visibility_transition;
    background-color: rgba($color-k-black, 0.5);

    &.transparent {
        background-color: transparent;
    }
}

.loader {
    color: $color-k-button;
    font-size: 6px;
    position: relative;
    margin: 0 auto;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    top: -2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: loaderAnim 1.8s infinite ease-in-out;
    animation: loaderAnim 1.8s infinite ease-in-out;

    &::after, &::before {
        border-radius: 50%;
        width: 2.5em;
        height: 2.5em;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation: loaderAnim 1.8s infinite ease-in-out;
        animation: loaderAnim 1.8s infinite ease-in-out;
        content: '';
        position: absolute;
    }

    &::before {
        left: -3.5em;
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }
    &::after {
        left: 3.5em;
    }
}

.notFound {
    text-align: center;
    color: $color-k-grey-text;

    .logo {
        height: 50px;
    }

    .err {
        font-size: 42pt;
    }
}

.bottomLinks {
    border-top: 2px solid $color-k-black;
    padding: 1rem 0;
    font-size: 16px;
    display: flex;
    flex-direction: column;

    .tosLink, .signOutLink, .subscriptionsLink, .homeLink {
        margin: 0.8rem 0;
    }
}

.bottomLinks, .menuLinks {
    .tosLink {
        color: $color-k-yellow;
        @include _color_transiition;

        &:hover {
            color: $color-k-yellow;
        }
    }

    .signOutLink, .tosLink, .subscriptionsLink, .homeLink, .pinLink, .accountLink {
        font-weight: 600;
        color: $color-k-grey-text;
        text-decoration: none;
        @include _color_transiition;

        &:hover {
            color: $color-k-yellow;
        }
    }

    .signOutLink {
        @include icon_mask($signOut-icon, 20px, 20px, $color-k-grey-text, $color-k-yellow, true)
    }

    .subscriptionsLink {
        @include icon_mask($subs-inon, 20px, 20px, $color-k-grey-text, $color-k-yellow, true)
    }

    .homeLink {
        @include icon_mask($home-icon, 20px, 20px, $color-k-grey-text, $color-k-yellow, true)
    }

    .pinLink {
        @include icon_mask($pin-icon, 20px, 20px, $color-k-grey-text, $color-k-yellow, true)
    }

    .accountLink {
        @include icon_mask($account-icon, 20px, 20px, $color-k-grey-text, $color-k-yellow, true)
    }

    .tosLink {
        @include icon_mask($tos-icon, 20px, 20px, $color-k-grey-text, $color-k-yellow, true)
    }
}

.errMsg {
    color: $color-k-grey-text !important;
    margin-bottom: 1rem;
    text-decoration: underline;
    font-size: 14px !important;
    text-align: center;
}

.success {
    p {
        margin-bottom: 1.5rem;
    }

    b {
        color: $color-k-button;
    }

    .successIcon {
        margin: 1rem auto;

        &.email {
            @include icon_mask($email-sent, 100px, 100px, $color-k-button);
        }

        &.default {
            @include icon_mask($done-icon, 100px, 100px, $color-k-button);
        }
        
        &.registration {
            @include icon_mask($reg-done-icon, 100px, 100px, $color-k-button);
        }

        &.password {
            @include icon_mask($password-changed-icon, 100px, 100px, $color-k-button);
        }
    }
}

.successInline {
    font-size: 14px;
    color: $color-k-grey-text;
}

.popup-content {
    margin: 1rem !important;
    background: $color-k-dark-grey;
    padding: 1.5rem;
    border-radius: 5px;
    min-width: 300px;
    max-width: 420px;
    -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
    animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

    h3 {
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 2rem;
    }
    
    p {
        margin-bottom: 2rem;
    }

    .pincode-input-container {
        margin-bottom: 1rem;
    }

    .errMsg {
        margin-bottom: 0;
    }

    .successInline {
        color: $color-k-button;
        text-align: center;
    }
}

.popup-arrow {
    color: rgb(255, 255, 255);
}

[role='tooltip'].popup-content {
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}
  
.popup-overlay {
    background: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
}

[data-popup='tooltip'].popup-overlay {
    background: transparent;
}

.noData {
    height: inherit;
    margin: auto 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    font-size: 36px;
    color: $color-k-grey-text;
    align-items: center;
    justify-content: center;
    cursor: default;

    &.noSubs, &.noSearchResults {
        p {
            font-size: 26px !important;
            text-align: center;
        }
    }

    .noDataIcon {
        @include icon_mask($no-data-icon, 70px, 70px, $color-k-grey-text);

    }

    .subscribeIcon {
        @include icon_mask($no-subs-icon, 70px, 70px, $color-k-grey-text);
    }

    .searchIcon {
        @include icon_mask($no-serach-results-icon, 70px, 70px, $color-k-grey-text);
    }
}

@keyframes anvil {
    0% {
        transform: scale(1) translateY(0px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    1% {
        transform: scale(0.96) translateY(10px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    100% {
        transform: scale(1) translateY(0px);
        opacity: 1;
        box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
}

@-webkit-keyframes loaderAnim {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}

@keyframes loaderAnim {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}